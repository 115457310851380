import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootState } from "../redux/store";
import { AllRouteConstants } from "../router/routes";

export interface NoRequireAuthProps {
  children: ReactElement;
}

export const NoRequireAuth: React.FC<NoRequireAuthProps> = ({ children }) => {
  const user = useSelector((state: IRootState) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (user.data) {
      navigate(AllRouteConstants.main.dashboard.index);
    }
  }, [user]);

  return children;
};
