/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Tenant, TenantOwner } from "../interfaces/Tenant";

type ListViewType = "LIST" | "GRID";

interface TenantSlice {
  data: Tenant[];
  initialized: boolean;
  viewMode: ListViewType;
}

const initialState: TenantSlice = {
  data: [],
  initialized: false,
  viewMode: "LIST",
};

export const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    initialize: (state, { payload }) => {
      state.data = payload;
      state.initialized = true;
    },

    add: (state, { payload }: { payload: Tenant }) => {
      state.data = [...state.data, payload];
    },

    toggleViewMode: (state, { payload }) => {
      state.viewMode = payload;
    },

    delete: (state, { payload }: { payload: string }) => {
      const filteredTenants = state.data.filter(
        (tenant) => tenant.tenantId !== payload
      );
      state.data = filteredTenants;
    },

    update: (
      state,
      { payload }: { payload: { tenantId: string; data: Partial<Tenant> } }
    ) => {
      const { data, tenantId } = payload;

      const keys = Object.keys(data);

      const tenantIndex = state.data.findIndex(
        (obj) => obj.tenantId === tenantId
      );

      if (tenantIndex !== -1) {
        keys.map((key) => {
          const value = data[key as keyof Tenant];
          console.log(keys, value, key,data)
          if (value !== undefined) {
            return ((state.data[tenantIndex][key as keyof Tenant] as
              | string
              | boolean
              | TenantOwner) = value);
          }
        });
      }
    },
  },
});

export const tenantActions = tenantsSlice.actions;

export default tenantsSlice.reducer;
