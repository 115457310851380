import React from "react";
import Button from "../../../../components/ui/button";
import { Tenant } from "../../../../interfaces/Tenant";
import "./styles.scss";

interface TenantListDisplayProps {
  tenants: Tenant[];
  onUpdateClick: (tenant: Tenant) => void;
}

const TenantListDisplay: React.FC<TenantListDisplayProps> = ({
  tenants,
  onUpdateClick,
}) => {

  return (
    <div className="av-tenant-list">
      <div className="card-container">
        <div className="av-tenant-list__card av-tenant-list__header">
          <div className="x-row-inner">
            <div className="x-col">
              <span>Tenant</span>
            </div>

            <div className="x-col">
              <span>Primary Admin</span>
            </div>

            <div className="x-col">
              <span>User Email</span>
            </div>

            <div className="x-col">
              <span>License</span>
            </div>
          </div>
        </div>
      </div>

      {tenants.map((data) => (
        <div
          key={data.tenantId}
          className="av-tenant-list__card av-tenant-list__item"
        >
          <div className="x-row-inner">
            <div className="x-col">
              <span>{data.tenantName}</span>
            </div>

            <div className="x-col">
              <span>{data.owner?.tenantOwnerName}</span>
            </div>

            <div className="x-col">
              <span>{data.owner?.tenantOwnerEmail}</span>
            </div>

            <div className="x-col">
              <span>{data.owner?.licenseType}</span>
            </div>

            <div className="actions">
              <Button
                variant="secondary"
                label={
                  <>
                    <i className="ri-settings-2-fill"></i>
                  </>
                }
                onClick={() => onUpdateClick(data)}
              />

              <Button
                variant="secondary"
                label={
                  <>
                    <i className="ri-shield-user-line"></i>&nbsp;Users
                  </>
                }
              />

              <Button
                label={
                  <>
                    Models&nbsp;<i className="ri-arrow-right-line"></i>
                  </>
                }
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TenantListDisplay;
