import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// scroll
import Scroll from "../components/scrollToTop/ScrollToTop";
import { IRootState } from "../redux/store";
import { AllRouteConstants } from "./routes";
import AuthLayout from "../layouts/authLayout/AuthLayout";
import AuthRoutes from "../features/auth/routes/Routes";
import DashboardHomeRoutes from "../features/dashboard/routes/Routes";
import { RequireAuth } from "../HoC/RequireAuth";
import DashboardLayout from "../layouts/dashboardLayout/DashboardLayout";
import TenantRoutes from "../features/tenants/routes/Routes";
import { NoRequireAuth } from "../HoC/NoRequireAuth";

function Router() {
  const user = useSelector((state: IRootState) => state.user);

  return (
    <BrowserRouter>
      <Scroll />
      <Routes>
        {/** [AUTH] */}
        <Route
          path={AllRouteConstants.auth.index}
          element={
            <NoRequireAuth>
              <AuthLayout />
            </NoRequireAuth>
          }
        >
          {AuthRoutes()}
        </Route>

        {/** [OTHERS] */}
        <Route
          path={AllRouteConstants.main.dashboard.index}
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          } // TODO: REPLACE WITH VALID ROUTE WRAPPER
        >
          {DashboardHomeRoutes()}

          {TenantRoutes()}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
