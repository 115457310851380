import { ChangeEventHandler } from "react";
import "./styles.scss";

interface InputProps {
  id: string;
  label: string;
  type?: string;
  required?: boolean;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  readOnly?: boolean;
  error: string | null;
}

const Input = ({
  id,
  label,
  placeholder,
  value,
  onChange,
  required = false,
  type = "text",
  readOnly,
  error,
}: InputProps) => {
  return (
    <div>
      <div
        className={`av-custom-input ${
          error ? "av-custom-input-error" : undefined
        }`}
      >
        <label htmlFor={id}>{label}</label>
        <input
          id={id}
          name={id}
          type={type}
          placeholder={placeholder}
          value={type === "file" ? undefined : value || ""}
          onChange={onChange}
          required={required}
          readOnly={readOnly}
        />
      </div>
      <i
        className={`av-custom-input__error ${
          !error ? "av-custom-input__error-hidden" : undefined
        } animate__animated animate__fadeIn`}
      >{`${id} ${error}`}</i>
    </div>
  );
};

export default Input;
