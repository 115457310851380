import React from "react";
import "./styles.scss"
import { Tenant } from "../../../../interfaces/Tenant";
import sample from "../../../../assets/images/unilagLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from "../../../../components/ui/button";

interface TenantGridDisplayProps {
    tenants: Tenant[];
}

const TenantGridDisplay: React.FC<TenantGridDisplayProps> = ({ tenants }) => {
    return (
        <div className="av-tenants-grid">
            <div className="x-row">
                {
                    tenants.map((tenant) => (
                        <div className="x-col" key={tenant.tenantId}>
                            <div className="container">
                                <div className="container__logo" style={{backgroundColor: tenant.color}}>
                                    <span className="img">
                                        <img src={`https://av-integration-static-assets-development.s3.us-east-1.amazonaws.com/avatars/${tenant.owner.tenantOwnerEmail}.png`} alt="logo" />
                                    </span>

                                    <div className="settings">
                                        <FontAwesomeIcon icon={icon({ name: "gear", style: "solid" })} />
                                        {" "}
                                        <span>Settings</span>
                                    </div>
                                </div>

                                <div className="container__content">
                                    <div className="title">
                                        {tenant.tenantName}
                                    </div>

                                    <div className="info">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </div>

                                    <div className="actions">
                                        <Button variant="secondary" label={<><i className="ri-shield-user-line"></i>&nbsp;Users</>} />

                                        <Button label={<>View Models&nbsp;<i className="ri-arrow-right-line"></i></>} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default TenantGridDisplay;