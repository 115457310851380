import React from "react";
import "./SpinnerStyles.scss";

const Spinner = () => {
  return (
    <div className="col-sm-2">
      <div className="sp sp-circle"></div>
    </div>
  );
};

export default Spinner;
