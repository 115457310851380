/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { AvTokens } from "../interfaces/AvTokens";

interface AuthSlice {
  data: AvTokens | null;
}

const initialState: AuthSlice = {
  data: null
}

export const authenticatedUserSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    signin: (state, { payload }) => {
      state.data = {
        "X-accToken": payload.access_token,
        "X-idToken": payload.token,
        "X-refToken": payload.refresh_token
      };
    },
    signout: (state) => {
      state.data = null;
    }
  },
});

export const authActions = authenticatedUserSlice.actions;

export default authenticatedUserSlice.reducer;
