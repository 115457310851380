import axios from "axios";
import { API_URL } from "../config/env";
import { LoginInput } from "../interfaces/LoginInput";
import { VerifySignInInput } from "../interfaces/VerifySignInInput";

class AuthService {
  login = (data: LoginInput) => {
    return axios.post(`${API_URL}/auth/signin`, data);
  };

  verifySignIn = (data: VerifySignInInput) => {
    return axios.post(`${API_URL}/auth/signin/verify`, data);
  };
}

const authService = new AuthService();
export default authService;
