import React from "react";
import Spinner from "../Spinner/Spinner";
import "./styles.scss";

interface ButtonProps {
  label: string | JSX.Element;
  onClick?: VoidFunction;
  variant?: "primary" | "secondary";
  type?: "button" | "submit";
  loading?: boolean;
  formId?: string;
  disable?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  label,
  variant = "primary",
  onClick,
  type = "button",
  loading = false,
  formId,
  disable,
}) => {
  return (
    <button
      type={type || "submit"}
      form={formId}
      className={`av-custom-button av-custom-button-${variant} `}
      onClick={onClick}
      disabled={disable || loading}
    >
      <main>
        {label}
        {loading && (
          <>
            <Spinner />
          </>
        )}
      </main>
    </button>
  );
};

export default Button;
