import { Outlet } from "react-router-dom";
import "./styles.scss";

const AuthLayout = () => {

    return (
        <div className="av-auth-layout">
            <Outlet />
        </div>
    )
}

export default AuthLayout;