import { Outlet } from "react-router-dom";
import DashboardHeader from "./dashboardHeader/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/store";
import { toggleSideBar } from "../../redux/sidebarSlice";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import DashboardDrawer from "./dashboardDrawer/DashboardDrawer";

const DashboardLayoutMobile = () => {
    const dispatch = useDispatch();
    const { sidebarOpened } = useSelector((state: IRootState) => state.sidebar);

    const handleOpenSide = () => {
        dispatch(toggleSideBar(!sidebarOpened));
    };

    const sidebarContainerRef = useRef(null);

    return (
        <div className="av-dashboard-layout-mobile">
            <CSSTransition 
                nodeRef={sidebarContainerRef} 
                in={sidebarOpened} 
                classNames="av-dashboard-layout-mobile__sidebar-container" 
                unmountOnExit 
                timeout={500}
            >
                <div 
                    ref={sidebarContainerRef} 
                    className={`av-dashboard-layout-mobile__sidebar-container`}
                    onClick={handleOpenSide}
                >
                    <div 
                        className={`av-dashboard-layout-mobile__sidebar-content ${sidebarOpened ? "slide-in-left" : "slide-out-left"} `}
                    >
                        <DashboardDrawer />
                    </div>
                </div>
            </CSSTransition>
            

            <DashboardHeader />

            <div className="av-page-content"><Outlet/></div>
        </div>
    );
}

export default DashboardLayoutMobile