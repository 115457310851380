import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import Button from "../../../../components/ui/button";
import Input from "../../../../components/ui/input/Input";
import InputFrame from "../../../../components/ui/inputFrame";
import Select from "../../../../components/ui/select";
import useApi from "../../../../hooks/useApi";
import { useForm } from "../../../../hooks/useForm";
import { AvTokens } from "../../../../interfaces/AvTokens";
import { CreateTenantInput, Tenant } from "../../../../interfaces/Tenant";
import { authActions } from "../../../../redux/authenticatedUserSlice";
import { IRootState } from "../../../../redux/store";
import { tenantActions } from "../../../../redux/tenantsSlice";
import { emailValidator } from "../../../../utils/validators/emailValidator";
import { emptyValidator } from "../../../../utils/validators/emptyValidator";
import "./styles.scss";
import { SketchPicker } from "react-color";
import ErrorWarning from "../../../../components/ui/errorWarning/ErrorWarning";
import DeleteTenant from "../DeleteTenant";
import TenantService from "../../../../services/TenantService";
import {
  UpdateTenantInput,
  UpdateTenantRequestData,
} from "../../../../interfaces/TenantInterfaces/UpdateTenantInterface";
import tenantService from "../../../../services/TenantService";

interface UpdateTenantProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  currentTenant: Tenant;
  deleteTenant: () => void;
}

const licenseTypeOptions = [
  { label: "Decision Coach", value: "Decision Coach" },
  { label: "Organizational", value: "Organizational" },
];

const UpdateTenant: React.FC<UpdateTenantProps> = ({
  isOpen,
  setIsOpen,
  currentTenant,
  deleteTenant,
}) => {
  const dispatch = useDispatch();
  const { data: tokens } = useSelector((state: IRootState) => state.user);

  const updateTenant = (data: {
    tokens: AvTokens;
    data: UpdateTenantRequestData;
  }) => tenantService.update(data.data, data.tokens);

  const updateTenantRef = useRef(null);

  const [selectedLogo, setSelectLogo] = useState<File | null>(null);

  const handleLogoSelected = (data: File | null) => {
    if (data) {
      if (data.size > 1024 ** 2 * 3)
        alert("File size cannot exceed more than 5MB");
      else setSelectLogo(data);
    } else {
      setSelectLogo(null);
    }
  };

  const updateTenantForm = useForm<UpdateTenantInput>(
    {
      licenseType: "Decision Coach",
      tenantName: currentTenant?.tenantName!,
      tenantOwnerEmail: currentTenant?.owner?.tenantOwnerEmail,
      tenantOwnerName: currentTenant?.owner?.tenantOwnerName,
      color: currentTenant?.color ?? "#168AFB",
    },
    {
      licenseType: emptyValidator,
      tenantName: emptyValidator,
      color: emptyValidator,
    }
  );

  useEffect(() => {
    if (selectedLogo) {
      const ext = selectedLogo.name.split(".").pop();
      const contentType = selectedLogo.type;

      updateTenantForm.onChange("ext", ext);
      updateTenantForm.onChange("contentType", contentType);
    }
  }, [selectedLogo]);

  useEffect(() => {
    updateTenantForm.reset();
  }, [currentTenant]);

  const updateTenantApi = useApi<
    { message: string },
    { tokens: AvTokens; data: UpdateTenantRequestData }
  >(updateTenant);

  const handleFormFieldChage: React.ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (e) => {
    const { name, value } = e.target;
    const key = name as keyof UpdateTenantInput;

    updateTenantForm.onChange(key, value);
  };

  const checkWhichFieldsWereChanged = (
    originalTenant: UpdateTenantInput,
    changedTenant: UpdateTenantInput
  ) => {
    const result: any = {};

    for (let tempKey in originalTenant) {
      let key = tempKey as keyof UpdateTenantInput;

      if (Object.keys(changedTenant).includes(key)) {
        if (originalTenant[key] !== changedTenant[key]) {
          result[key] = changedTenant[key];
        }
      }
    }
    return result;
  };
  const handleClose = () => {
    updateTenantApi.reset();
    updateTenantForm.reset();
    setIsOpen(false);
  };
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (updateTenantApi.loading) return;

    updateTenantForm.resetFormErrors();

    const originalTenant = {
      licenseType: currentTenant.owner.licenseType,
      tenantName: currentTenant?.tenantName!,
      tenantOwnerEmail: currentTenant?.owner?.tenantOwnerEmail,
      tenantOwnerName: currentTenant?.owner?.tenantOwnerName,
      color: currentTenant?.color,
    };

    const changedFields = checkWhichFieldsWereChanged(
      originalTenant,
      updateTenantForm.form
    );

    const valid = updateTenantForm.validate();

    if (valid && tokens) {
      try {
        const response = await updateTenantApi.request({
          data: {
            update: changedFields,
            tenantId: currentTenant.tenantId,
            ownerId: currentTenant.owner.ownerId,
          },
          tokens,
        });
        console.log(updateTenantApi.error);
        console.log(response);

        dispatch(
          tenantActions.update({
            data: changedFields,
            tenantId: currentTenant.tenantId,
          })
        );

        handleClose();
      } catch (error) {
        console.log("[ERROR]", error);
      }
    }
  };

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  return (
    <CSSTransition
      nodeRef={updateTenantRef}
      in={isOpen}
      timeout={500}
      unmountOnExit
      classNames={"av-add-tenant"}
    >
      <div ref={updateTenantRef} className="av-add-tenant">
        <div className="av-add-tenant__close" onClick={() => setIsOpen(false)}>
          <i className="ri-close-line"></i>
        </div>

        <div
          className={`av-add-tenant__content ${
            isOpen ? "slide-in-right" : "slide-out-right"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="x-div">
            <h3>Update Tenant: {updateTenantForm.form.tenantName}</h3>

            <div className="x-text-content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </div>

            <div className="spacer"></div>

            <form id="updateTenantForm" onSubmit={handleSubmit}>
              <Input
                id="tenantName"
                label="Tenant Name"
                value={updateTenantForm.form.tenantName}
                onChange={handleFormFieldChage}
                error={updateTenantForm.formErrors.tenantName}
              />

              <Input
                id="tenantOwnerName"
                label="Tenant Owner"
                value={updateTenantForm.form.tenantOwnerName}
                onChange={handleFormFieldChage}
                error={updateTenantForm.formErrors.tenantOwnerName}
                readOnly
              />

              <Input
                id="tenantOwnerEmail"
                label="Tenant Email"
                value={updateTenantForm.form.tenantOwnerEmail}
                onChange={handleFormFieldChage}
                error={updateTenantForm.formErrors.tenantOwnerEmail}
                readOnly
              />

              <Input
                id="tenantLogo"
                label="Tenant Logo"
                type="file"
                value={currentTenant?.uploadURL ?? ""}
                onChange={(e) => {
                  handleLogoSelected(e.target.files ? e.target.files[0] : null);
                }}
                error={null}
              />

              <InputFrame label="Tenant Color">
                <div
                  className="color-menu-wrapper"
                  style={{ backgroundColor: updateTenantForm.form.color }}
                >
                  <div
                    className="color-menu-wrapper__cro"
                    onMouseOver={() => setIsPickerOpen(true)}
                    onMouseLeave={() => setIsPickerOpen(false)}
                  >
                    {isPickerOpen && (
                      <div className="popup">
                        <SketchPicker
                          color={updateTenantForm.form.color}
                          onChange={(color) => {
                            updateTenantForm.onChange("color", color.hex);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </InputFrame>

              <Select
                id="licenseType"
                label="License Type"
                placeholder="Select License Type"
                value={updateTenantForm.form.licenseType || ""}
                options={licenseTypeOptions}
                onChange={handleFormFieldChage}
              />
            </form>
          </div>

          <div className="btn-container-div">
            <ErrorWarning error={updateTenantApi.error.error?.message} />

            <Button
              type="submit"
              formId="updateTenantForm"
              loading={updateTenantApi.loading}
              label={"Update Tenant"}
            />
            <button
              className="av-update-tenant-delete-button"
              onClick={deleteTenant}
            >
              Delete Tenant
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default UpdateTenant;
