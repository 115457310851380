import { Route } from "react-router-dom";
import { AllRouteConstants } from "../../../router/routes";
import { Dashboard } from "../pages";
import ComponentWithTitleDisplay from "../../../components/ComponentWithTitle";

const DashboardHomeRoutes = () => {
  return (
    <>
      <Route
        index
        element={<ComponentWithTitleDisplay component={<Dashboard />} title="Dashboard | Allovance" />}
      />

      <Route
        path={AllRouteConstants.main.dashboard.dashboard}
        element={<ComponentWithTitleDisplay component={<Dashboard />} title="Dashboard | Allovance" />}
      />
    </>
  );
};

export default DashboardHomeRoutes;
