import React, { useState } from "react"
import "./styles.scss";

interface SearchInputProps {
    placeholder?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ placeholder }) => {
    const [input, setInput] = useState("");

    const clear = () => setInput("");

    return <div className="av-search-input">
        <div className="search">
            <i className="ri-search-line"></i>
        </div>

        <input type="text" value={input} onChange={(e) => setInput(e.target.value)} placeholder={placeholder} />

        {input && (<div className="cancel" onClick={clear}>
            <i className="ri-close-circle-fill"></i>
        </div>)}
    </div>
}

export default SearchInput