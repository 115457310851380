import "./TableSkeletonLoaderStyles.scss";

interface TableSkeletonLoaderInterface {
  rowNo: number;
  columnValues: string[];
}
export const TableSkeletonLoader = (props: TableSkeletonLoaderInterface) => {
  const { rowNo, columnValues } = props;
  const renderedRows = [...Array(rowNo)].map((e, i) => (
    <div key={i}>
      <Row columnValues={columnValues} />
    </div>
  ));

  return (
    <div className=" bg-white shadow-md rounded py-4 px-3">
      <div className="skeleton-loader__card skeleton-loader__header">
        <div className="x-row-inner">
          {columnValues?.map((item: string, id: number) => (
            <div className="x-col" key={id}>
              <span>{item}</span>
            </div>
          ))}
        </div>
      </div>

      {renderedRows}
    </div>
  );
};

const Row = ({ columnValues }: { columnValues: string[] }) => (
  <div className="tableSkeletonrow">
    {columnValues.map((item: string, id: number) => (
      <div key={id} className="skeleton"></div>
    ))}
  </div>
);

export default Row;
