import { Outlet } from "react-router-dom";
import DashboardDrawer from "./dashboardDrawer/DashboardDrawer";
import DashboardHeader from "./dashboardHeader/DashboardHeader";

const DashboardLayoutDesktop = () => {
    return (
        <div className="av-dashboard-layout-desktop">
            <DashboardHeader />

            <div className="av-dashboard-layout-desktop__content">
                <DashboardDrawer />

                <div className="outlet">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default DashboardLayoutDesktop