import { Route } from "react-router-dom";
import ComponentWithTitleDisplay from "../../../components/ComponentWithTitle";
import { AllRouteConstants } from "../../../router/routes";
import {
  Login,
} from "../pages";

const AuthRoutes = () => {
  return (
    <>
      <Route index element={ <ComponentWithTitleDisplay component={<Login />} title="Login" />  } />
      <Route path={AllRouteConstants.auth.login} element={  <ComponentWithTitleDisplay component={<Login />} title="Login" /> } />
    </>
  );
};

export default AuthRoutes;
