import { DashboardDrawerType } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./DashboardDrawerStyles.scss";
import { IRootState } from "../../../redux/store";
import { toggleSideBar } from "../../../redux/sidebarSlice";
import { authActions } from "../../../redux/authenticatedUserSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AllRouteConstants } from "../../../router/routes";
import NavItem from "./NavItem";

const DashboardDrawer = (props: DashboardDrawerType) => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { sidebarOpened, isMobile } = useSelector(
    (state: IRootState) => state.sidebar
  );

  const handleSideItemClick = (route: string) => () => {
    if (isMobile) {
      dispatch(toggleSideBar(!sidebarOpened));
      setTimeout(() => {
        navigate(route);
      }, 200);
      return;
    }
    navigate(route);
  };

  return (
    <div
      id="drawer_container"
      className={`av-dashboard-drawer`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="av-dashboard-drawer__close">
        <i className="ri-close-line"></i>
      </div>

      <div className="wrapper">
        <div className="av-dashboard-drawer__nav-items">
          <NavItem 
            icon={<FontAwesomeIcon icon={icon({ name: "gauge-high" })} />}
            title="Dashboard"
            primaryRoute={AllRouteConstants.main.dashboard.index}
          />

          <NavItem 
            icon={<FontAwesomeIcon icon={icon({ name: "user" })} />}
            title="Tenant Management"
            primaryRoute={AllRouteConstants.main.tenants.index}
          />

          <div className="spacer" />
        </div>

        <div className="av-dashboard-drawer__nav-items">
          <div className="av-nav-item">
            <div className="icon">
              <i className="ri-settings-2-line"></i>
            </div>

            <div className="title">Settings</div>
          </div>

          <div className="av-nav-item">
            <div className="icon">
              <i className="ri-file-text-line"></i>
            </div>

            <div className="title">User Guide</div>
          </div>

          <div className="spacer" />

          <div className="av-drawer-user"> 
            <div className="img">

            </div>

            <div className="title">sherrif farghal</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDrawer;
