import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/ui/button";
import SearchInput from "../../../components/ui/SearchInput";
import useApi from "../../../hooks/useApi";
import { AvTokens } from "../../../interfaces/AvTokens";
import { Tenant } from "../../../interfaces/Tenant";
import { IRootState } from "../../../redux/store";
import { tenantActions } from "../../../redux/tenantsSlice";
import tenantService from "../../../services/TenantService";
import AddTenant from "../components/AddTenant";
import TenantTable from "../components/TenantListDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import TenantGridDisplay from "../components/TenantGridDisplay";
import { TableSkeletonLoader } from "../../../components/ui/skeletonLoader/TableSkeletonLoader";
import UpdateTenant from "../components/UpdateTenant";
import { dummyTenantData } from "../data/DummyTenants";
import { current } from "@reduxjs/toolkit";
import DeleteTenant from "../components/DeleteTenant";
import TenantService from "../../../services/TenantService";

export const Tenants = () => {
  const [openTogglers, setOpenTogglers] = useState({
    addTenant: false,
    updateTenant: false,
    deleteTenant: false,
  });

  const dispatch = useDispatch();

  const fetchAllTenants = (tokens: AvTokens) => tenantService.fetchAll(tokens);

  const [currentTenant, setCurrentTenant] = useState<Tenant | null>(null);

  const {
    initialized,
    data,
    viewMode: view,
  } = useSelector((state: IRootState) => state.tenants);

  const { data: tokens } = useSelector((state: IRootState) => state.user);

  const fetchTenantApi = useApi<Tenant[], AvTokens>(fetchAllTenants);

  const executeFetchAllTenants = async () => {
    const result = await fetchTenantApi.request(tokens || undefined);

    if (result && Array.isArray(result)) {
      const filteredResults = result.filter(
        (result) => result.isDelete !== true
      );
      dispatch(tenantActions.initialize(filteredResults));
    }
  };

  useEffect(() => {
    if (
      tokens &&
      Object.keys(tokens).length >= 3 &&
      !initialized &&
      !fetchTenantApi.loading
    ) {
      executeFetchAllTenants();
    }
  }, [tokens, initialized]);

  const toggleViewType = () => {
    if (view === "GRID") {
      localStorage.setItem("view", "LIST");
      dispatch(tenantActions.toggleViewMode("LIST"));
    } else {
      localStorage.setItem("view", "GRID");
      dispatch(tenantActions.toggleViewMode("GRID"));
    }
  };

  const handleUpdateTenant = (tenant: Tenant) => {
    setCurrentTenant(tenant);
    setOpenTogglers({ ...openTogglers, updateTenant: true });
  };

  const toggleDrawerState = (
    state: boolean,
    type: "addTenant" | "deleteTenant" | "updateTenant"
  ) => {
    return setOpenTogglers({ ...openTogglers, [type]: state });
  };

  useEffect(() => {
    const view = localStorage.getItem("view");

    if (view) {
      dispatch(tenantActions.toggleViewMode(view));
    }
  }, []);

  return (
    <>
      <AddTenant
        isOpen={openTogglers.addTenant}
        setIsOpen={(state: boolean) => toggleDrawerState(state, "addTenant")}
      />

      {currentTenant && (
        <>
          <UpdateTenant
            isOpen={openTogglers.updateTenant}
            setIsOpen={(state: boolean) =>
              toggleDrawerState(state, "updateTenant")
            }
            currentTenant={currentTenant as Tenant}
            deleteTenant={() => toggleDrawerState(true, "deleteTenant")}
          />

          <DeleteTenant
            isOpen={openTogglers.deleteTenant}
            setIsOpen={(state) => toggleDrawerState(state, "deleteTenant")}
            currentTenant={currentTenant as Tenant}
            closeAll={() => setCurrentTenant(null)}
          />
        </>
      )}

      <div className="av-tenants-page animate__animated animate__fadeIn">
        <div className="av-tenants-page__table-container">
          <div className="controls">
            <div className="controls__item">
              <span>
                <i className="ri-information-line"></i>
              </span>

              <span>(SUPER ADMIN) Active Tenants</span>
            </div>

            <div className="controls__item">
              <div className="grid_list_toggle" onClick={toggleViewType}>
                {view === "LIST" ? (
                  <FontAwesomeIcon icon={icon({ name: "grip" })} />
                ) : (
                  <FontAwesomeIcon icon={icon({ name: "bars" })} />
                )}
              </div>

              <SearchInput placeholder={"Search Users"} />

              <Button
                label={
                  <>
                    <i className="ri-add-line"></i>&nbsp;Add Tenant
                  </>
                }
                onClick={() =>
                  setOpenTogglers({ ...openTogglers, addTenant: true })
                }
              />
            </div>
          </div>

          {view === "LIST" && <div className="spacer"></div>}

          {/** Tenants Table */}

          {view === "LIST" && (
            <>
              {fetchTenantApi.loading ? (
                <TableSkeletonLoader
                  columnValues={[
                    "Tenant",
                    "Primary Admin",
                    "User Email",
                    "License",
                    "",
                  ]}
                  rowNo={4}
                />
              ) : (
                <>
                  {data.length && (
                    <TenantTable
                      tenants={data}
                      onUpdateClick={handleUpdateTenant}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>

        {view === "GRID" && <TenantGridDisplay tenants={data} />}
      </div>
    </>
  );
};
