import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import Button from "../../../../components/ui/button";
import Input from "../../../../components/ui/input/Input";
import InputFrame from "../../../../components/ui/inputFrame";
import Select from "../../../../components/ui/select";
import useApi from "../../../../hooks/useApi";
import { useForm } from "../../../../hooks/useForm";
import { AvTokens } from "../../../../interfaces/AvTokens";
import { CreateTenantInput, Tenant } from "../../../../interfaces/Tenant";
import { authActions } from "../../../../redux/authenticatedUserSlice";
import { IRootState } from "../../../../redux/store";
import { tenantActions } from "../../../../redux/tenantsSlice";
import tenantService from "../../../../services/TenantService";
import { emailValidator } from "../../../../utils/validators/emailValidator";
import { emptyValidator } from "../../../../utils/validators/emptyValidator";
import "./styles.scss";
import { SketchPicker } from "react-color";
import ErrorWarning from "../../../../components/ui/errorWarning/ErrorWarning";
import TenantService from "../../../../services/TenantService";

interface AddTenantProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

const licenseTypeOptions = [
  { label: "Decision Coach", value: "Decision Coach" },
  { label: "Organizational", value: "Organizational" },
];

const AddTenant: React.FC<AddTenantProps> = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { data: tokens } = useSelector((state: IRootState) => state.user);

  const addTenant = (data: { tokens: AvTokens; input: CreateTenantInput }) =>
    tenantService.create(data.input, data.tokens);

  const addTenantRef = useRef(null);

  const [selectedLogo, setSelectLogo] = useState<File | null>(null);

  const handleLogoSelected = (data: File | null) => {
    if (data) {
      if (data.size > 1024 ** 2 * 3)
        alert("File size cannot exceed more than 5MB");
      else setSelectLogo(data);
    } else {
      setSelectLogo(null);
    }
  };

  const addTenantForm = useForm<CreateTenantInput>(
    {
      licenseType: undefined,
      tenantName: "",
      tenantOwnerEmail: "",
      tenantOwnerName: "",
      color: "#168AFB",
    },
    {
      licenseType: emptyValidator,
      tenantName: emptyValidator,
      tenantOwnerEmail: emailValidator,
      tenantOwnerName: emptyValidator,
      color: emptyValidator,
    }
  );

  useEffect(() => {
    if (selectedLogo) {
      const ext = selectedLogo.name.split(".").pop();
      const contentType = selectedLogo.type;

      addTenantForm.onChange("ext", ext);
      addTenantForm.onChange("contentType", contentType);
    }
  }, [selectedLogo]);

  const addTenantApi = useApi<
    { data: Tenant },
    { tokens: AvTokens; input: CreateTenantInput }
  >(addTenant);

  const handleFormFieldChage: React.ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (e) => {
    addTenantForm.onChange(
      e.target.name as keyof CreateTenantInput,
      e.target.value
    );
  };

  useEffect(() => {
    if (addTenantApi.error?.code > 400 && addTenantApi.error?.code <= 401) {
      dispatch(authActions.signout());
    }
  }, [addTenantApi.error]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (addTenantApi.loading) return;

    addTenantForm.resetFormErrors();
    addTenantApi.reset();

    const valid = addTenantForm.validate();

    if (valid && tokens) {
      try {
        const tenant = await addTenantApi.request({
          input: addTenantForm.form,
          tokens,
        });

        if (tenant) {
          dispatch(tenantActions.add(tenant.data));

          setIsOpen(false);

          addTenantForm.reset();
          addTenantApi.reset();
          addTenantForm.resetFormErrors();
          setSelectLogo(null);

          if (selectedLogo && tenant.data.uploadURL)
            await tenantService.uploadLogoToS3({
              url: tenant.data.uploadURL,
              body: selectedLogo,
            });
        }
      } catch (error) {
        console.log("[ERROR]", error);
      }
    }
  };

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  return (
    <CSSTransition
      nodeRef={addTenantRef}
      in={isOpen}
      timeout={500}
      unmountOnExit
      classNames={"av-add-tenant"}
    >
      <div ref={addTenantRef} className="av-add-tenant">
        <div className="av-add-tenant__close" onClick={() => setIsOpen(false)}>
          <i className="ri-close-line"></i>
        </div>

        <div
          className={`av-add-tenant__content ${
            isOpen ? "slide-in-right" : "slide-out-right"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="x-div">
            <h3>Create New Tenant</h3>

            <div className="x-text-content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </div>

            <div className="spacer"></div>

            <form id="addTenantForm" onSubmit={handleSubmit}>
              <Input
                id="tenantName"
                label="Tenant Name"
                value={addTenantForm.form.tenantName}
                onChange={handleFormFieldChage}
                error={addTenantForm.formErrors.tenantName}
              />

              <Input
                id="tenantOwnerName"
                label="Tenant Owner"
                value={addTenantForm.form.tenantOwnerName}
                onChange={handleFormFieldChage}
                error={addTenantForm.formErrors.tenantOwnerName}
              />

              <Input
                id="tenantOwnerEmail"
                label="Tenant Email"
                value={addTenantForm.form.tenantOwnerEmail}
                onChange={handleFormFieldChage}
                error={addTenantForm.formErrors.tenantOwnerEmail}
              />

              <Input
                id="tenantLogo"
                label="Tenant Logo"
                type="file"
                onChange={(e) => {
                  handleLogoSelected(e.target.files ? e.target.files[0] : null);
                }}
                error={null}
              />

              <InputFrame label="Tenant Color">
                <div
                  className="color-menu-wrapper"
                  style={{ backgroundColor: addTenantForm.form.color }}
                >
                  <div
                    className="color-menu-wrapper__cro"
                    onMouseOver={() => setIsPickerOpen(true)}
                    onMouseLeave={() => setIsPickerOpen(false)}
                  >
                    {isPickerOpen && (
                      <div className="popup">
                        <SketchPicker
                          color={addTenantForm.form.color}
                          onChange={(color) => {
                            addTenantForm.onChange("color", color.hex);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </InputFrame>

              <Select
                id="licenseType"
                label="License Type"
                placeholder="Select License Type"
                value={addTenantForm.form.licenseType || ""}
                options={licenseTypeOptions}
                onChange={handleFormFieldChage}
              />
            </form>
          </div>

          <div className="x-div">
            <ErrorWarning error={addTenantApi.error.error?.message} />

            <Button
              type="submit"
              formId="addTenantForm"
              loading={addTenantApi.loading}
              label={"Add & Notify Tenant"}
            />

            <div className="x-text-content">
              Tenant Owner will create a new password when logging in for the
              first time
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default AddTenant;
