export const AllRouteConstants = {
  auth: {
    index: "/auth",
    login: "/auth/signin",
  },
  main: {
    dashboard: {
      index: "/",
      dashboard: "/dashboard"
    },
    tenants: {
      index: "/tenants",
    }
  }
};
