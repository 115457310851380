import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/authenticatedUserSlice";

export const Dashboard = () => {
    const dispatch = useDispatch();

    const handleLogout = () => dispatch(authActions.signout());

    return (
      <div className="al-dashboard-page animate__animated animate__fadeIn ">
        <h4>DASHBOARD</h4>

        <button type="button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    );
}