import "./DashboardLayout.scss";
import DashboardLayoutDesktop from "./DashboardLayoutDesktop";
import DashboardLayoutMobile from "./DashboardLayoutMobile";

const DashboardLayout = () => {
  return <div className="av-dashboard-layout">
    <DashboardLayoutDesktop />

    <DashboardLayoutMobile />
  </div>
}

export default DashboardLayout;
