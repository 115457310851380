import { Helmet, HelmetProvider } from "react-helmet-async";

interface RouteComponentProps {
  title: string;
  component: JSX.Element;
}

function ComponentWithTitleDisplay(props: RouteComponentProps) {
  const { title, component } = props;
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        {component}
      </>
    </HelmetProvider>
  );
}

export default ComponentWithTitleDisplay