import logo from "../../../assets/images/AllovanceLogoDark.svg";
import Input from "../../../components/ui/input/Input";
import Button from "../../../components/ui/button";
import { LoginInput } from "../../../interfaces/LoginInput";
import { useForm } from "../../../hooks/useForm";
import { emailValidator } from "../../../utils/validators/emailValidator";
import { emptyValidator } from "../../../utils/validators/emptyValidator";
import useApi from "../../../hooks/useApi";
import { User } from "../../../interfaces/User";
import authService from "../../../services/AuthService";
import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/authenticatedUserSlice";
import ErrorWarning from "../../../components/ui/errorWarning/ErrorWarning";
import { passwordValidator } from "../../../utils/validators/passwordValidator";

const signin = (data: LoginInput) => authService.login(data);

export const Login = () => {
  const dispatch = useDispatch();

  const loginForm = useForm<LoginInput>(
    { email: "", password: "" },
    { email: emailValidator, password: passwordValidator }
  );

  const loginApiRequest = useApi<User, LoginInput>(signin);

  const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    loginForm.onChange("email", e.target.value);
  };

  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    loginForm.onChange("password", e.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (loginApiRequest.loading) return;

    loginForm.resetFormErrors();
    loginApiRequest.reset();
    const valid = loginForm.validate();
    if (valid) {
      if (!loginForm.form.email || !loginForm.form.password) {
        return;
      }

      try {
        const user = await loginApiRequest.request(loginForm.form);

        if (user) {
          dispatch(authActions.signin(user));
        }
      } catch (error) {}
    }
  };

  return (
    <div className="av-login-page">
      <form className="av-login-page__form" onSubmit={handleSubmit}>
        <img src={logo} alt="logo" />

        <h1>Login</h1>
        <span>
          Login Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </span>

        <div className="av-login-page__form__input-container">
          <Input
            label="Username"
            id="username"
            value={loginForm.form.email}
            onChange={handleChangeEmail}
            type="email"
            required={true}
            error={loginForm.formErrors.email}
          />

          <Input
            label="Password"
            id="password"
            value={loginForm.form.password}
            onChange={handleChangePassword}
            type="password"
            required={true}
            error={loginForm.formErrors.password}
          />
        </div>

        <Button
          loading={loginApiRequest.loading}
          label={"Login To Allovance"}
          type="submit"
        />

        <div className="av-login-page__form__extras">
          <span>
            <a href="#">Privacy Policy</a>
          </span>{" "}
          &#x2022;{" "}
          <span>
            <a href="#">Terms of Use</a>
          </span>
        </div>
        <ErrorWarning error={loginApiRequest.error.error?.message} />
      </form>
    </div>
  );
};
