import { Route } from "react-router-dom";
import ComponentWithTitleDisplay from "../../../components/ComponentWithTitle";
import { AllRouteConstants } from "../../../router/routes";
import {
  Tenants,
} from "../pages";

const TenantRoutes = () => {
  return (
    <>
      <Route path={AllRouteConstants.main.tenants.index} element={ <ComponentWithTitleDisplay component={<Tenants />} title="Tenants | Allovance" />  } />
    </>
  );
};

export default TenantRoutes;
