import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { Tenant } from "../../../../interfaces/Tenant";
import { IRootState } from "../../../../redux/store";
import "./styles.scss";
import TenantService from "../../../../services/TenantService";
import { AvTokens } from "../../../../interfaces/AvTokens";
import useApi from "../../../../hooks/useApi";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  DeleteTenantProps,
  IDeleteTenanatForm,
  IDeleteTenantBody,
} from "../../../../interfaces/TenantInterfaces/DeleteTenantInterfaces";
import { useForm } from "../../../../hooks/useForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../../../components/ui/input/Input";
import { deleteTenantConfirmationValidation } from "../../../../utils/validators/deleteTenantConfirmation";
import { replaceSpacesWithHyphens } from "../../utils/replaceSpacesWithHyphens";
import ErrorWarning from "../../../../components/ui/errorWarning/ErrorWarning";
import { tenantActions } from "../../../../redux/tenantsSlice";
import tenantService from "../../../../services/TenantService";

const DeleteTenant: React.FC<DeleteTenantProps> = ({
  isOpen,
  setIsOpen,
  currentTenant,
  closeAll,
}) => {
  const dispatch = useDispatch();
  const { data: tokens } = useSelector((state: IRootState) => state.user);

  const deleteTenantService = (data: {
    body: IDeleteTenantBody;
    tokens: AvTokens;
  }) => tenantService.delete(data.body, data.tokens);

  const deleteTenantRequest = useApi<
    any,
    { body: IDeleteTenantBody; tokens: AvTokens }
  >(deleteTenantService);

  const deleteTenantForm = useForm<IDeleteTenanatForm>(
    {
      ownerid: currentTenant.owner.ownerId,
      tenantId: currentTenant.tenantId,
      confirmation: "",
    },
    {
      confirmation: (data) => {
        return deleteTenantConfirmationValidation(
          data,
          replaceSpacesWithHyphens(tenantName)
        );
      },
    }
  );

  const handleChangeConfirmationo: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    deleteTenantForm.onChange("confirmation", e.target.value);
  };

  const executedeleteTenant: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    const valid = deleteTenantForm.validate();

    if (valid) {
      await deleteTenantRequest.request({
        body: deleteTenantForm.form,
        tokens: tokens as AvTokens,
      });

      dispatch(tenantActions.delete(currentTenant.tenantId));
      deleteTenantForm.reset();
      deleteTenantForm.resetFormErrors();

      setIsOpen(false);
      setTimeout(() => {
        closeAll();
      }, 500);
    }
  };

  const { tenantName } = currentTenant;

  const deleteTenantRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={deleteTenantRef}
      in={isOpen}
      timeout={500}
      unmountOnExit
      classNames={"av-delete-tenant"}
    >
      <div ref={deleteTenantRef} className="av-delete-tenant">
        <div
          className={`av-delete-tenant__content ${
            isOpen ? "slide-in-right" : "slide-out-right"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="av-delete-tenant__close"
            onClick={() => setIsOpen(false)}
          >
            <i className="ri-close-line"></i>
          </div>
          <form
            action=""
            className="av-delete-tenant__content"
            onSubmit={executedeleteTenant}
          >
            <div className="av-delete-tenant__content-top">
              <div className="av-delete-tenant__content-top_container">
                <h3>
                  <FontAwesomeIcon
                    icon={icon({
                      name: "triangle-exclamation",
                      style: "solid",
                    })}
                    className="av-delete-tenant__content-icon"
                  />
                  You Are About To Delete {tenantName}
                </h3>
                <p>
                  Deleting a tenant is a permanent action and will remove all
                  models, projects and data associated with that tenant. If you
                  are sure that you would like to proceed, please type
                  <i> {replaceSpacesWithHyphens(tenantName)}</i> in the
                  confirmation field below and then click Delete.
                </p>
                <Input
                  label="Confirmation"
                  id="confirmation"
                  value={deleteTenantForm.form.confirmation}
                  onChange={handleChangeConfirmationo}
                  required={true}
                  error={deleteTenantForm.formErrors.confirmation}
                />
              </div>

              <ErrorWarning error={deleteTenantRequest.error.error?.message} />
            </div>

            <div className="av-delete-tenant__content-bottom">
              <button type="submit">
                {deleteTenantRequest.loading ? "Deleting..." : "Delete"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </CSSTransition>
  );
};

export default DeleteTenant;
