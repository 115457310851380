import "./styles.scss";

interface InputFrameProps {
    label: string;
    children: JSX.Element
}

const InputFrame = ({label, children}: InputFrameProps) => {
    return (
        <div className="av-custom-input-frame">
            <label className="av-custom-input-frame__label">{label}</label>

            <div className="av-custom-input-frame__content">{children}</div>
        </div>
    )
}

export default InputFrame