import axios, { AxiosInstance } from "axios";
import { API_URL } from "../config/env";
import { AvTokens } from "../interfaces/AvTokens";
import { CreateTenantInput, UploadTenantLogo } from "../interfaces/Tenant";
import { Dispatch } from "@reduxjs/toolkit";
import { IDeleteTenantBody } from "../interfaces/TenantInterfaces/DeleteTenantInterfaces";
import {
  UpdateTenantInput,
  UpdateTenantRequestData,
} from "../interfaces/TenantInterfaces/UpdateTenantInterface";

class TenantService {
  fetchAll = (tokens: AvTokens) => {
    return axios.get(`${API_URL}/tenants`, {
      headers: {
        ...tokens,
      },
    });
  };

  create = (data: CreateTenantInput, tokens: AvTokens) => {
    return axios.post(`${API_URL}/tenants`, data, {
      headers: {
        ...tokens,
      },
    });
  };

  delete = (data: IDeleteTenantBody, tokens: AvTokens) => {
    return axios.delete(`${API_URL}/tenants`, {
      data,
      headers: {
        ...tokens,
      },
    });
  };

  update = (data: UpdateTenantRequestData, tokens: AvTokens) => {
    return axios.patch(`${API_URL}/tenants`, data, {
      headers: {
        ...tokens,
      },
    });
  };

  uploadLogoToS3 = (data: UploadTenantLogo) => {
    return axios.put(data.url, data.body, {
      headers: {
        "Content-Type": data.body.type,
        "x-amz-acl": "public-read",
        // 'Origin': 'https://admin.allovance.com'
      },
    });
  };
}
const tenantService = new TenantService();
export default tenantService;
